import isEmpty from 'lodash/isEmpty'
import { getEnv } from 'shared'

export const isVessel = !isEmpty(getEnv({ name: 'VITE_VESSEL_CODE', defaultValue: '' }))

export const SLIDE_BARS = {
  approval: {
    myDraft: 'myDraft',
    forApproval: 'forApproval',
    inProgress: 'inProgress',
    approved: 'approved',
    rejected: 'rejected',
    reference: 'reference',
    signature: 'signature',
    approval: 'approval',
  },
  personalMail: {
    email: 'email',
    saved: 'personalSaved',
    inbox: 'personalInbox',
    sent: 'personalSent',
    friendList: 'personalFriendList',
  },
  teamMail: {
    teamMail: 'teamMail',
    inbox: 'teamMailInbox',
    sent: 'teamMailSent',
    unregisteredMail: 'teamMailUnregisteredMail',
    saved: 'teamMailSaved',
    deleted: 'deletedMail',
    address: 'teamMailAddress',
  },
  userInformation: {
    userInformation: 'userInformation',
  },
  vesselBudget: {
    vesselBudget: 'vesselBudget',
  },
  commonCode: {
    commonCode: 'commonCode',
    vesselCode: 'vesselCode',
    crewCode: 'crewCode',
    companyCode: 'companyCode',
    portCode: 'portCode',
    departmentCode: 'departmentCode',
    safetyCode: 'safetyCode',
    masterCode: 'masterCode',
  },
}

export enum MODULE_CODES {
  EPRO = 'EPRO',
  EWMS = 'EWMS',
}
